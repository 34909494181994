.menu-principal {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
  li.menu-item {
    a {
      padding: 5px 1rem;
      text-decoration: none;
      // color: $color-grey-3;
      // text-transform: uppercase;
      font-weight: $font-weight-medium;
      font-size: $font-size-small;
      letter-spacing: 1px;
    }
    &.current-menu-item {
      > a {
        color: $color-grey-4;
      }
    }
  }
  li.menu-item-has-children {
    position: relative;
    padding-right: 0.5rem;
    .sub-menu {
      background-color: $color-dark;
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      display: none;
      position: absolute;
      box-shadow: $box-shadow;
      max-width: 200px;
      min-width: 150px;
      top: 2rem;
      z-index: 2;
      border-radius: 5px;
      li {
        a {
          color: $color-background;
          font-size: 0.9rem;
          padding: 0.5rem 1.25rem;
          font-weight: normal;
          display: block;
          text-decoration: none;
          font-size: $font-size-smaller;
          &:hover {
            background-color: $color-grey-2;
            color: $color-white;
          }
        }
      }
    }
  }
}

.menu-principal {
  .menu-item-has-children > a::before {
    content: "";
    position: absolute;
    display: block;
    background: url("../assets/ico/ico-dropdown.png") no-repeat center center;
    background-size: auto;
    width: 9px;
    height: 9px;
    background-size: cover;
    opacity: 0.6;
    right: 6px;
    top: 8px;
  }
}

.header--home {
  .menu-item {
    a {
      color: $color-background;
    }
    &.current-menu-item {
      > a {
        color: $color-background !important;
      }
    }
  }
  .menu-item-has-children > a::before {
    filter: invert(1) contrast(200);
  }
  .btn-overlay {
    filter: invert(1) contrast(200);
  }
}

.menu-hero {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  & > li.menu-item {
    margin-right: 2rem;
    @media screen and (max-width: 575px) {
      margin-right: 1rem;
      margin-bottom: 0.5rem;
    }
    a {
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 500;
    }
  }
}

.nav-year {
  display: flex;
  align-items: center;
}

.btn-overlay {
  width: auto;
  height: auto;
  cursor: pointer;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  i {
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $color-background;
    font-weight: bold;
  }
}

.m-overlay {
  display: none;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  color: $color-dark;
  background: $color-background;
  // padding: 5rem;
  overflow-y: auto;
  @media screen and (max-width: 575px) {
    padding: 1.2rem;
  }

  &__close {
    position: absolute;
    top: 1rem;
    right: 2.5rem;
    z-index: 12;
    @media screen and (max-width: 767px) {
      top: 0.2rem;
      right: 0.8rem;
    }
    .bi {
      font-size: 4rem;
      cursor: pointer;
      // transform: scale(1.5);
      @media screen and (max-width: 767px) {
        font-size: 2.5rem;
      }
    }
  }
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1.2rem;
    @media screen and (min-width: 767px) {
      padding: 4rem;
    }
    @media screen and (min-width: 992px) {
      padding: 5rem;
    }
  }
  &__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    ul {
      margin-bottom: 0rem;
    }
  }
  &__social {
    margin-top: 1rem;
    .social-footer {
      margin-bottom: 0;
    }
  }
  &__search {
    margin-top: 1rem;
    .bi::before {
      font-size: 1.5rem;
    }
  }
}

.menu-overlay {
  @include ul-reset;
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    &:hover {
      color: $color-primary !important;
    }
  }

  > li.menu-item {
    margin-bottom: 2rem;
    @media screen and (max-width: 767px) {
      margin-bottom: 0.5rem;
    }
    & > a {
      font-size: $header-2;
      color: $color-grey-2;
      font-weight: $font-weight-medium;
      @media screen and (max-width: 767px) {
        font-size: 1.3rem;
      }
    }
  }
  li.menu-item-has-children {
    display: flex;
    flex-direction: column;
    align-items: center;
    ul.sub-menu {
      margin-top: 0.5rem;

      @media screen and (max-width: 767px) {
        margin-top: 0.5rem;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5rem 2rem;
      @include ul-reset;
      li.menu-item {
        a {
          font-size: $header-4;
          color: $color-grey-3;
          @media screen and (max-width: 767px) {
            font-size: 1rem;
          }
        }
      }
    }
  }
}

.nav-copy {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: $font-size-small;
}
