.slider {
  &__item {
    margin: 0 1px;
  }
}

.t-project {
  display: block;
  &__content {
    position: relative;
    @media screen and (max-width: 767px) {
      aspect-ratio: 4 / 3;
    }

    img {
      width: 100%;
      max-height: 350px;
      @media screen and (max-width: 767px) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }
  }

  &__overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.9);
    color: $color-white;
    opacity: 0;
    transition: $trans;
    &:hover {
      display: none;
      opacity: 1;
      transition: $trans;
    }
  }
  &__title {
    font-size: 1.2rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    width: 80%;
    text-align: center;
  }
  &__year {
    font-size: $font-size-smaller;
    text-align: center;
    padding: 0 0.5rem;
  }
}

/* masonry grid styles */

/* clear fix */
.g-proyectos:after {
  content: "";
  display: block;
  clear: both;
}

.grid-gutter {
  width: 0;
}

// .g-proyectos__item,
.grid-sizer {
  width: 25%;
  @media screen and (max-width: 991px) {
    width: 50%;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
  }
  &--1 {
    width: 100%;
  }
  &--2 {
    width: 50%;
  }
  &--3 {
    width: 33%;
  }
  &--4 {
    width: 25%;
  }
}

.g-proyectos {
  width: 100%;
  margin: 0 auto !important;
  position: relative;
  max-width: 98%;

  &--at {
    margin-top: $space-2 !important;
  }

  &__item {
    position: absolute;
    img {
      height: auto;
      width: 100%;
      transition: $trans;
    }
  }

  &__item--en-proceso {
    //display: none;
  }

  &__item--concursos {
    // display: none;
  }

  &__enlace {
    position: relative;
    display: block;
    &:hover {
      .g-proyectos__overlay {
        opacity: 1;
        transition: $trans;
      }
      img {
        // transform: scale(1.3);
        // transition: $trans;
        height: auto;
        width: 100%;
        vertical-align: top;
      }
    }
  }
  &__img {
    position: relative;
    // filter: grayscale(14%) sepia(25%) saturate(50%);
  }

  &__overlay {
    position: absolute;
    // top: 8px;
    left: 0;
    //right: 8px;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5);
    color: $color-white;
    opacity: 0;
    transition: $trans;
  }
}

.s-proyectos {
  display: flex;
  flex-direction: column;

  &__item {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    margin-bottom: 3rem;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  &__thumb {
  }

  &__img {
    img {
      width: 100%;
      height: 360px;
      object-fit: cover;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 3rem;
  }

  &__title {
  }

  &__year {
  }
}

/* ---- .grid-item ---- */

// .g-proyectos .g-proyectos__item .grid-item
.proyecto-hero {
  position: relative;
  height: 80vh;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: 992px) and (min-height: 800px) {
    height: 70vh;
  }
  @media screen and (max-width: 1199px) {
    height: 60vh;
  }
  @media screen and (max-width: 767px) {
    height: 60vh;
  }
  @media screen and (max-width: 991px) {
    height: 40vh;
  }
  &__cover {
    position: relative;
    display: flex;
    height: 100%;
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.2);
      background: linear-gradient(
        0deg,
        rgba(2, 0, 36, 0.2) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      @media screen and (max-width: 767px) {
        background: linear-gradient(
          0deg,
          rgba(2, 0, 36, 0.4) 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__container {
    display: flex;
    height: 100%;
    align-items: flex-end;
    position: relative;
    z-index: 2;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  &__content {
    margin-bottom: $space-2;
    @media screen and (max-width: 767px) {
      margin-bottom: $space-1;
    }
  }
  &__titulo {
    color: $color-white;
    font-size: $display-2;
    @media screen and (min-width: 768px) {
      font-size: $display-2;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
    }
  }
}

#proyecto__content {
  display: none;
  transition: $trans;
}

#proyecto__excerpt,
#leer-menos,
#leer-mas {
  transition: $trans;
}

.leer-mas,
.leer-menos {
  cursor: pointer;
  font-weight: $font-weight-bold;
  &__icon {
    font-weight: $font-weight-bold;
  }
  &:hover {
    color: $color-primary;
  }
}

.leer-menos {
  display: none;
}

.proyecto-datos {
  &__items {
    display: flex;
    justify-content: flex-start;
    gap: $space-2;
    flex-wrap: wrap;
    margin-bottom: $space-2;
    @media screen and (max-width: 767px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: $space-1;
      margin-bottom: $space-1 !important;
    }
  }
  &__item {
    max-width: 200px;
  }

  &__label {
    font-size: $font-size-small;
    font-weight: bold;
    margin-bottom: $font-size-smaller / 2;
    @media screen and (max-width: 767px) {
      font-size: $font-size-smaller;
    }
  }

  &__output {
    font-size: $font-size-small;
    @media screen and (max-width: 767px) {
      font-size: $font-size-smaller;
    }
  }
  &__fotografia {
    font-size: $font-size-small;
    @media screen and (max-width: 767px) {
      font-size: $font-size-smaller;
    }
  }
}

.proyecto-extras {
  &__item {
    font-size: $font-size-smaller;
    display: flex;
    gap: 10px;
  }
  &__label {
    font-weight: bold;
  }
  &__output {
  }
}

.proyecto-galeria {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 1rem;
  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  @media screen and (max-width: 1199px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @media screen and (min-width: 768px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2px;
  }
  // @media screen and (max-width: 480px) {
  //   grid-template-columns: 1fr;
  // }
  &--publi {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  &__item {
    img {
      width: 100%;
      height: auto;
    }
  }
}

.proyecto-feat {
  &__card {
    display: flex;
    gap: 2rem;
    @include full-width;
    margin-bottom: $space-5;
    &:nth-child(2n) {
      @media screen and (min-width: 992px) {
        flex-direction: row-reverse;
      }
    }
    &:last-child {
      margin-bottom: $space-0;
    }
    @media screen and (max-width: 991px) {
      flex-direction: column;
      margin-bottom: $space-3;
    }
    // &:last-child {
    //   margin-bottom: ;
    // }
  }

  &__image {
    flex: 0 0 65%;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    padding: 0 3rem;
    @media screen and (max-width: 767px) {
      padding: 0 2rem;
    }
    h3 {
      font-size: $header-1;
      margin-bottom: $space-2;
      @media screen and (max-width: 767px) {
        font-size: $header-2;
      }
    }
  }
}

.proyecto-video {
  margin-bottom: 2px;
  &__item {
    box-shadow: $box-shadow-large;
    iframe {
      border-radius: $border-radius-min;
    }
  }
}

.page--proyecto {
  p:first-child {
    font-size: $header-3;
    @media screen and (max-width: 767px) {
      font-size: $header-4;
    }
  }
}

body.dark-mode {
  background-color: $color-grey-1;
  color: $color-white;
  h2,
  h3,
  h4,
  p,
  a {
    color: $color-white;
  }
  .header--sticky {
    a {
      color: $color-dark;
    }
    #logo {
      .st1 {
        fill: $color-dark;
      }
    }
    .header__burger {
      img {
        filter: initial;
      }
    }
  }
  #logo {
    .st1 {
      fill: $color-white;
    }
  }
  .header__burger {
    img {
      filter: invert(10) contrast(100%) grayscale(2);
    }
  }
}

.ead {
  background-color: $color-grey-6;
  padding: $space-3 0;
  margin-top: $space-3;
  @media screen and (max-width: 767px) {
    padding: calc($font-size * 2) 0;
  }
  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $space-4;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: $space-2;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: $space-1;
    // margin-bottom: $space-2;
    @media screen and (max-width: 767px) {
      gap: $font-size;
    }
  }

  &__arrow {
    display: flex;
    align-items: center;
    .bi {
      font-size: 1.2rem;
    }
  }

  &__foto {
    img {
      width: 100%;
      height: auto;
    }
    &--despues {
    }
    &--antes {
    }
  }
}
