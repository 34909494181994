.card-2 {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  gap: 5rem;
  @media screen and (max-width: 575px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  &__content {
  }

  &__picture {
    img {
      width: 100%;
      height: auto;
    }
  }
}

.card-2-fw {
  @include full-width;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 0rem;
  position: relative;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
  &__content {
    @media screen and (min-width: 992px) {
      margin-left: -10rem;
    }
    @media screen and (max-width: 991px) {
      padding: 1.2rem;
    }
    display: flex;
    align-items: center;
    padding-right: 5rem;
    .display-1 {
      @media screen and (min-width: 1199px) and (max-width: 1399px) {
        font-size: 4rem !important;
      }
      @media screen and (min-width: 992px) and (max-width: 1199px) {
        font-size: 3rem;
      }
      @media screen and (max-width: 767px) {
        font-size: $header-3;
      }
    }
  }

  &__picture {
    img {
      width: 100%;
      height: auto;
    }
  }
}

.wfeat {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  &__header {
    display: flex;

    margin-bottom: 4rem;
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }
  &__pic-01 {
    flex: 0 0 65%;
    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    padding: 4rem;
    @media screen and (max-width: 767px) {
      padding: 2rem;
    }
  }
}
