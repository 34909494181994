// Reset Ul
@mixin ul-reset {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

@mixin full-width {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}
