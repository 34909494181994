.g-premios {
  &__item {
    display: grid;
    grid-template-columns: 300px 1fr;

    gap: 3rem;
    margin-bottom: 3rem;
    padding-bottom: 3rem;
    border-bottom: 1px dotted $color-grey-4;
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
      gap: 1.5rem;
    }
  }
  &__figure {
    img {
      display: block;
      width: 100%;
      max-height: 200px;
      object-fit: cover;
    }
  }

  &__content {
  }

  &__titulo {
    h2 {
      font-size: $header-4;
    }
  }

  &__arquitectos {
    margin-bottom: 1rem;
    color: $color-primary;
    font-size: $font-size-small;
  }

  &__premio {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    li {
      display: flex;
      padding-left: 0rem;
      font-size: $font-size-small;
      &:before {
        margin-right: 1rem;
        display: block;
        margin-bottom: 5px;
        content: "—";
      }
    }
  }
}

.g-concursos {
  margin-bottom: $space-3;
  &__item {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px dotted $color-grey-4;
    &--single {
      margin-bottom: 2rem;
      padding-bottom: 1rem;
    }
  }

  &__titulo {
    font-weight: bold;
    margin-bottom: 5px;
  }

  &__concepto {
    font-size: $font-size-small;
  }

  &__arquitectos {
    font-size: $font-size-small;
  }
}
