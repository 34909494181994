.g-team {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4rem;
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
  &__item {
  }

  &__picture {
    margin-bottom: 2rem;
    img {
      width: 100%;
      height: auto;
    }
  }

  &__name {
    font-size: $header-4;
  }

  &__position {
    font-size: $header-6;
  }
}

.g-member {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 4rem;
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  &__picture {
  }

  &__content {
  }
}
