.page-title {
  padding-top: 3rem;
  padding-bottom: 1rem;
  @media screen and (max-width: 767px) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  &__h {
    font-size: $header-2;
    @media screen and (max-width: 767px) {
      font-size: $display-4;
    }
  }
  &__breadcrumbs {
    font-size: $font-size;
    @media screen and (max-width: 767px) {
      font-size: $font-size-smaller;
    }
    span {
      color: $color-grey-3;
    }
    a {
      color: $color-grey-1;
    }
    @media screen and (min-width: 992px) {
      > span {
        > span {
          > a {
            margin-right: 0.2rem;
          }
          > span {
            a {
              margin-left: 0.2rem;
              margin-right: 0.2rem;
            }
          }
        }
      }
      .breadcrumb_last {
        margin-left: 0.2rem;
      }
    }
  }
}
