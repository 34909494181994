.an-overlay {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: black;
  //background-color: $color-background;
  display: flex;
  justify-content: center;
  align-items: center;
  &__logo {
  }

  &__img {
    width: 160px;
    height: auto;
    //filter: invert(100%);
  }
}
