.wpcf7 {
  margin: 0rem 0 2rem;

  .form-group {
    margin-bottom: 1rem;
  }

  .form-label {
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-bottom: 0.2rem !important;
  }

  .form-control {
    width: 100%;
    padding: 0.575rem 0.75rem;
    font-size: 0.85rem;
    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
  }

  .wpcf7-not-valid-tip {
    font-size: 12px;
    color: red;
  }
}

.wpcf7-acceptance {
  font-size: 0.6rem;
  color: $color-grey-3;
  color: rgba(0, 0, 0, 0.4);

  .wpcf7-list-item {
    // margin-left: 0;
  }
}

.wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.5em 1em;
  border-radius: 8px;
}

.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
  background-color: #398f14;
  color: $color-background;
}

.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000;
  background-color: #ff0000;
  color: $color-background;
}

.wpcf7-spam-blocked {
  border: 2px solid #ffa500;
  background-color: #ffa500;
}

.wpcf7-acceptance-missig {
  border: 2px solid #ffa500 !important;
  background-color: #ffa500 !important;
}

.m-search {
  position: fixed;
  z-index: 50;
  display: none;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: $color-background;
  justify-content: center;
  align-items: center;
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  &__close {
    position: absolute;
    right: 2.2rem;
    top: 0.7rem;
    i {
      font-size: 2.5rem;
      color: $color-dark;
    }
  }
}

.buscador {
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: center; //margin-left: auto;

  &__container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 575px) {
      margin-bottom: 4rem;
    }
  }
  &__form {
    width: 90%;
    flex-direction: column;
    display: flex;
    align-items: center;
  }
  &__wrap {
    width: 90%;
  }
  &__input {
    width: 100%;
    font-size: 3rem;
    background-color: $color-background;
    border: 0;
    border-bottom: 1px solid $color-grey-4;
    color: $color-primary;
    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
    }
    &:visited {
      color: $color-dark;
      text-decoration: none;
    }
    &::placeholder {
      color: $color-grey-5;
    }
    &:focus-visible {
      outline: none;
    }
  }

  &__img {
    width: 20px;
    height: auto;
  }
}
