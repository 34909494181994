.header {
  padding: 1.2rem 1.5rem;
  display: flex;
  align-items: center;
  z-index: 3;
  transition: $trans;
  position: sticky;
  width: 100%;
  top: 0;
  background-color: $color-white;
  @media screen and (min-width: 1401px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  @media screen and (max-width: 991px) {
    padding: 1rem 1.2rem;
  }

  // &--fixed {
  //   position: fixed;
  //   top: 0;
  //   width: 100%;
  // }

  &--sticky {
    box-shadow: $box-shadow;
    padding: 0.8rem 1.5rem;
    transition: $trans;
    border-bottom: 1px solid $color-grey-6;

    @media screen and (max-width: 991px) {
      padding: 0.7rem 1.2rem;
    }
    @media screen and (min-width: 1401px) {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }
  &--home + &--sticky {
    background-color: $color-dark;
  }
  &--home {
    position: fixed !important;
    width: 100%;
    background: none;
    .logo {
      .st0,
      .st1 {
        fill: $color-white;
      }
    }
    .bi {
      color: $color-white;
    }
  }

  &__logo {
    margin-right: 4rem;
    svg {
      width: 170px;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 150px;
      }
    }
  }

  &__nav {
    margin-left: auto;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &__search {
    margin-right: 5px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    cursor: pointer;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &__burger {
    @media screen and (min-width: 992px) {
      display: none;
    }
    margin-left: 2rem;
    @media screen and (max-width: 991px) {
      margin-left: auto;
    }
  }
}

.hero-footer {
  padding: 1rem 0;
  .header-5 {
  }
}
