html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.wrapper {
  margin: 0 auto;
  padding: 0 16.66% 50px;
  width: 100%;
}

.timeline {
  position: relative;
  //max-width: 980px;
  margin: 0 auto;
  padding-bottom: 5rem;
  @media screen and (min-width: 992px) {
    //desktop
    display: grid;
    grid-template-columns: auto 1fr;
  }
  @media screen and (max-width: 991px) {
  }
  &__nav {
    z-index: 0;
    top: 0;
    transition: top 0.3s ease-out;

    ul li {
      margin: 15px 0;
      padding-left: 0;
      list-style-type: none;
      color: #bfc1c3;
      border-bottom: 1px dotted rgba(0, 0, 0, 0.3);
      cursor: pointer;
      transition: all 0.3s ease-out;

      &.active {
        font-weight: bold;
        color: #f94125;
        border-bottom: 1px dotted transparent;
        transform: scale(1.2);
      }

      &:hover {
        color: #000;
      }
    }
  }
  &__nav--desktop {
    position: fixed;
    @media screen and (min-width: 992px) {
    }
    @media screen and (max-width: 991px) {
      display: none;
    }
    ul {
      list-style: none;
      list-style-position: inside;
      margin: 15px 0 50px 0;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      li {
        margin: 0.4rem 0.5rem;
      }
    }
    &-sticky {
      top: 80px !important;
      transition: $trans;
    }
  }
  &__nav--mvl {
    position: relative;
    background-color: $color-white;
    transition: $trans;
    @media screen and (min-width: 992px) {
      display: none;
    }

    ul {
      list-style: none;
      list-style-position: inside;
      margin: auto 0;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(5, minmax(0, 1fr));
      padding-left: 0;
      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
      @media screen and (max-width: 575px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      li {
        margin: 0.2rem 2.5rem;
        padding: 0.3rem 0;
        text-align: center;
        @media screen and (max-width: 575px) {
          margin: 0.2rem 2rem;
        }
      }
    }
  }

  &__modal {
  }
  &__boton {
    z-index: 9;
    @media screen and (min-width: 992px) {
      display: none;
    }
    @media screen and (max-width: 991px) {
      position: fixed;
      bottom: 0;
      right: 0;
      margin: 1rem;
    }
  }

  &__section {
    @media screen and (max-width: 991px) {
      padding-top: 100px;
    }
    @media screen and (max-width: 767px) {
      padding-top: 60px;
    }
  }
  &__milestone {
    margin: 0 0 100px 19%;
    position: relative;
    @media screen and (max-width: 575px) {
      margin-bottom: 60px;
    }
    &::after {
      content: "";
      position: absolute;
      background-color: $color-dark;
      height: 1px;
      width: 90%;
      left: 10%;
      top: 50%;
      overflow: hidden;
      @media screen and (max-width: 1199px) {
        left: 13%;
        width: 87%;
      }
      @media screen and (max-width: 575px) {
        left: 20%;
        width: 80%;
      }
    }
    @media screen and (max-width: 1199px) {
      margin-left: 22%;
    }
    @media screen and (max-width: 991px) {
      margin-left: 0;
    }
  }

  &__wrapper {
    padding: 0 0 100px 19%;
    @media screen and (max-width: 1199px) {
      padding-left: 22%;
    }
    @media screen and (max-width: 991px) {
      padding-left: 0;
    }
    @media screen and (max-width: 575px) {
      padding-bottom: 60px;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: auto 3fr 2fr;
    //margin-bottom: 2rem;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: auto 3fr;
    }
  }

  &__item-anyo {
    align-self: center;
    justify-self: center;
    display: inline-flex;
    text-align: center;
    position: relative;
    @media screen and (max-width: 575px) {
      top: -28px;
    }
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      background-color: $color-grey-5;
      width: 1px;
      height: 9rem;
      height: 20rem;
      overflow: hidden;
      left: 34%;
      top: 100%;
      @media screen and (max-width: 575px) {
        left: 39%;
        height: 10rem;
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      background-color: $color-grey-5;
      width: 1px;
      height: 9rem;
      height: 6rem;
      overflow: hidden;
      left: 34%;
      top: -140%;
      @media screen and (max-width: 575px) {
        left: 39%;
        height: 16rem;
        top: -370%;
      }
    }
    span {
      position: relative;
      background-color: $color-grey-5;
      color: $color-white;
      padding: 1.4rem 1rem;
      border-radius: 3rem;
      margin-right: 2rem;
      @media screen and (max-width: 575px) {
        margin-right: 1rem;
        padding: 1rem 0.6rem;
      }
    }
  }

  &__enlace {
  }

  &__item-info {
    padding: 1rem;
    align-self: center;
    justify-self: self-start;
    @media screen and (min-width: 992px) {
      padding-left: 2rem;
    }
  }

  &__item-titulo {
    @media screen and (max-width: 575px) {
      margin-bottom: 0.25rem;
    }
  }

  &__item-tipo {
    font-size: $font-size-smaller;
    @media screen and (max-width: 575px) {
    }
  }

  &__item-img {
    padding: 2rem 0 1rem 0;
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 480px;
  }
}

.timeline__item:nth-child(1) .timeline__item-anyo::before {
  width: 0;
}

.timeline__item:nth-last-child(1) .timeline__item-anyo::after {
  width: 0;
}

.timeline__item-info-mvl {
  margin-bottom: 1rem;
  @media screen and (min-width: 576px) {
    display: none;
  }
}

.timeline__item-info {
  @media screen and (max-width: 575px) {
    display: none;
  }
}
