.header-proyectos {
  margin-top: $space-2;
}

.filtros {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: $space-1;
  padding-bottom: 1rem;
  padding-top: 1rem;

  // border-top: 1px solid $color-grey-4;
  // border-bottom: 1px solid $color-grey-4;

  @media screen and (max-width: 767px) {
    margin-bottom: 0rem;
    justify-content: space-between;
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      gap: $font-size;
    }
  }

  &__filter {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 0;
    margin-top: 0;
    font-size: initial;
    @media screen and (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.boton-filtros {
  // padding: 0.2rem 1rem;
  // border: 3px solid $color-grey-5;
  // border-radius: 50px;
  font-weight: $font-weight-medium;
  color: $color-grey-2;
  display: inline-flex;
  text-transform: lowercase;
  font-size: $font-size;
  // &:hover {
  //   background-color: $color-grey-5;
  //   color: $color-grey-1;
  // }
  // &[aria-expanded="true"] {
  //   background-color: $color-grey-5;
  //   color: $color-grey-1;
  // }
  &--active {
    color: $color-primary;
  }
  &--inline {
    @media screen and (max-width: 767px) {
      padding: 0.2rem 0rem;
      border: none;
      margin-right: 1rem;
    }
  }
}

.menu-proyectos {
  //margin-right: 1rem;
  @media screen and (max-width: 767px) {
  }
  &__nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    display: flex;

    li {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      @media screen and (max-width: 767px) {
        padding-right: 0;
      }
      a {
        // padding: 5px 1rem 5px 1rem;
        // font-size: $header-5;
        // @extend .header-all;
        // margin-bottom: 0;
        // font-weight: $font-weight-normal;
        @extend .boton-filtros;
      }
    }
  }
}

.filtros-content {
  @media screen and (min-width: 992px) {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  &__collapse {
    .facetwp-facet {
      margin-bottom: $space-2 !important;
    }
  }
  &__terms {
    margin-bottom: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
      justify-content: flex-start;
    }
  }

  &__h1 {
    display: flex;
    margin-bottom: 0;
  }

  &__term {
    padding: 0.2rem 0.6rem;
    letter-spacing: 1px;
    font-size: 0.9rem;
    @media screen and (max-width: 767px) {
      margin-right: 0.2rem;
      margin-bottom: 0.2rem;
    }
    // &:nth-last-child(1) {
    //   padding-right: 0;
    //   margin-right: 0;
    // }
    &--active {
      color: $color-primary;
      font-weight: bold;
    }
  }
}

// .nav-facetwp {
//   width: 100%;
//   // display: grid;
//   // grid-template-columns: 1fr auto;
// }

.facetwp-facet {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0px !important;

  .facetwp-radio,
  .facetwp-radio.checked {
    background-image: none !important;
  }

  .facetwp-radio {
    padding: 0.2rem 0.6rem;
    padding-left: 0;
    // background-color: $color-grey-4;
    // color: $color-white !important;
    margin-right: 0.2rem;
    margin-bottom: 0.2rem;
    // border-radius: 4px;
    letter-spacing: 1px;
    font-size: $font-size-small;
    &:hover {
      color: $color-primary;
    }
    .facetwp-counter {
      display: none;
    }
  }
  .facetwp-radio.checked {
    // background-color: $color-grey-1;
    color: $color-primary;
  }
}

/*  inputs ------------------   */
// .facetwp-type-fselect,
// .facetwp-dropdown {
//   .fs-wrap {
//     .fs-label-wrap {
//       font-size: $header-5;
//       font-weight: $font-weight-normal;
//       background: 0;
//       border: 0;
//       cursor: pointer;
//       .fs-label {
//         padding: 6px 8px 6px 15px;
//       }
//       .fs-arrow {
//         left: 0px;
//         right: initial;
//         border: 0;
//         background: url(../assets/ico/ico-plus.png) no-repeat center center;
//         background-size: 10px 10px;
//         width: 10px;
//         height: 10px;
//       }
//     }
//     .fs-dropdown {
//       width: auto;
//       min-width: 224px;
//       box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
//       padding: 0.5rem;
//       border-radius: 0.5rem;
//       .fs-options {
//         max-height: initial;
//         overflow: initial;
//         .fs-option {
//           padding: 10px 12px;
//         }
//         .fs-option.d1 {
//           &:hover {
//             background-color: $color-grey-6;
//           }
//           .fs-option-label {
//             padding-left: 0;
//           }
//         }
//         .fs-option:not(.d1) {
//           color: $color-primary;
//           &:hover {
//             color: $color-grey-6;
//             background-color: $color-primary;
//           }
//           .fs-option-label {
//             font-weight: bold !important;
//             padding-left: 0;
//             font-weight: $font-weight-normal;
//           }
//         }
//       }
//     }
//   }
// }

/*  inputs ------------------   */

.facetwp-map-filtering {
  display: none;
}

.facetwp-dropdown.form-select {
  border-color: $color-grey-4;
}

.g-proyectos__load-more .facetwp-facet-infinite_scroll {
  text-align: center;
  display: block;
  .facetwp-load-more {
    @include button;
    padding: 0.625rem 1.25em;
    font-size: $font-size-small;
    background-color: $color-primary;
    color: $color-white;
  }
}

.facetwp-load-more.facetwp-hidden {
  display: none !important;
}

// .facetwp-checkbox {
//   display: flex;
//   align-items: center;
//   background: none !important;
//   background-color: $color-dark !important;
//   color: $color-white !important;
//   padding-left: 0 !important;
//   margin-right: 0.5rem !important;
//   margin-bottom: 0.5rem !important;
//   padding: 0.4em 1em !important;
//   border-radius: 2px !important;
//   &.checked {
//     background-color: $color-primary !important;
//   }

//   &:nth-last-child(1) {
//     //margin-right: 0 !important;
//   }
// }

// .facetwp-counter {
//   display: none !important;
// }

// .facetwp-facet-date_as_year {
//   .facetwp-checkbox {
//     display: flex;
//     align-items: center;
//     background: none !important;
//     background-color: transparent !important;
//     color: $color-dark !important;
//     padding-left: 0 !important;
//     margin-right: 1.2rem !important;
//     padding: 0 !important;
//     font-weight: bold !important;
//     font-size: $font-size-bigger !important;
//     &.checked {
//       color: $color-primary !important;
//     }
//     &.disabled {
//       color: $color-grey-4 !important;
//     }

//     &:nth-last-child(1) {
//       //margin-right: 0 !important;
//     }
//   }
// }

// .facetwp-search {
//   color: $color-dark;
//   &:visited {
//     color: $color-dark;
//     text-decoration: none;
//   }
// }
