.footer {
  padding: $space-3 0 $space-2;
  // background-color: $color-white;
  &__content {
    font-size: $font-size-small;
    padding-top: $space-2;
    // border-top: 1px solid $color-grey-3;
    text-align: center;
    @media screen and (max-width: 767px) {
      font-size: $font-size-smaller;
    }
  }
}

.social-footer {
  margin-bottom: $font-size;
  .wp-block-wpzoom-blocks-social-icons {
    display: flex;
    justify-content: center;
    .social-icon-link {
      .social-icon {
        background-color: $color-grey-4 !important;
        transition: $trans;
        &:hover {
          background-color: $color-grey-4 !important;
          transition: $trans;
        }
      }
    }
  }
}

.next-gen {
  padding-bottom: $space-1;

  @media screen and (max-width: 575px) {
    padding-bottom: $space-1;
  }
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $space-1 $space-1;
    flex-wrap: wrap;
    flex-direction: column;
    padding-top: $space-1;
    border-top: 1px dashed $color-white;
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      gap: $font-size / 2;
    }
  }

  &__logos {
    display: flex;
    gap: $space-1;
  }
  &__img {
    img {
      width: 180px;
      height: auto;
      @media screen and (max-width: 575px) {
        width: 100px;
      }
    }
  }

  &__description {
    color: $color-dark;
    font-size: $font-size-smaller;
    @media screen and (max-width: 575px) {
      text-align: center;
    }
  }
}
