.enfocados-claim {
  padding: $space-3 0 $space-4;
  @media screen and (max-width: 767px) {
    padding: $space-3 0 $space-3;
  }
  &__title {
    font-size: $display-4;
    @media screen and (min-width: 992px) {
      width: 70%;
    }
    @media screen and (max-width: 991px) {
      font-size: $header-2-res;
    }
  }
}

.estudio-claim {
  &__titulo {
    font-size: $display-2;
    position: relative;
    z-index: 1;
    @media screen and (min-width: 992px) {
      width: 80%;
    }
    @media screen and (min-width: 992px) and (max-width: 1399px) {
      font-size: $display-3;
      margin-bottom: $space-4;
    }
    @media screen and (max-width: 991px) {
      font-size: $header-2-res;
      margin-bottom: $space-2;
    }
  }
  &__img {
    img {
      width: 100%;
      height: 70vh;
      object-fit: cover;
      margin-top: -8rem;
      @media screen and (max-width: 1399px) {
        margin-top: initial;
      }
      @media screen and (max-width: 767px) {
        margin-top: 0;
        height: auto;
        object-fit: initial;
      }
    }
  }
}

.estudio-claim-02 {
  padding: $space-5 0;
  &__title {
    font-size: $header-1;
    // font-weight: bold;
    margin-bottom: $space-3;
    color: $color-primary;
    @media screen and (min-width: 1200px) {
      width: 80%;
    }
    @media screen and (min-width: 992px) {
      width: 80%;
    }
    @media screen and (max-width: 991px) {
      font-size: $header-2-res;
      margin-bottom: $space-2;
    }
  }
  &__subtitle {
    font-size: $header-2;
    @media screen and (max-width: 991px) {
      font-size: $header-2-res;
    }
  }
}

.buscamos {
  &__title {
    font-size: $header-2;
    margin-bottom: $space-2;
  }
  &__descripcion {
    p {
      line-height: 1.8;
    }
  }
  &__header {
    display: flex;
    margin-bottom: 4rem;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }
  &__pic-01 {
    flex: 0 0 65%;
    img {
      width: 100%;
      height: auto;
    }
  }
  .container {
    @media screen and (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__content {
    padding: 4rem;
    @media screen and (max-width: 767px) {
      padding: 2rem;
    }
  }

  &__pic-02 {
    margin-left: 10vw;
    @media screen and (max-width: 767px) {
      margin-left: 0;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}

.galeria-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: $font-size;
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: $font-size / 2;
  }
  &__item {
    img {
      width: 100%;
      height: auto;
    }
  }
}

.claim-final {
  padding: $space-5 0;
  &__title {
    margin-bottom: $space-4;
    font-size: $header-1;

    @media screen and (min-width: 992px) {
      width: 70%;
    }
    @media screen and (max-width: 991px) {
      font-size: $header-2-res;
      margin-bottom: $space-2;
    }
  }

  &__subtitle {
    font-size: $header-3;
    line-height: 1.8;
    @media screen and (min-width: 992px) {
      width: 70%;
    }
    @media screen and (max-width: 991px) {
      font-size: $header-3-res;
    }
  }
}

.hello {
  margin-top: $space-5;
  padding: $space-5 0;
  @media screen and (max-width: 767px) {
    margin-top: $space-3;
    padding: $space-3 0;
  }
  &__title {
    font-size: $display-2;
    width: 80%;
    margin-bottom: $space-3;
  }
}
