$color-primary: #578ad6;
$color-primary-light: #a0c6ff;
$color-primary-dark: #405d89;

$color-secondary: #ff447f;
$color-secondary-light: #fe86ac;
$color-secondary-dark: #c62256;

$color-accent: #5d50c6;
$color-accent-light: rgba(93, 80, 198, 0.1);
$color-accent-dark: #473d99;

$color-grey-1: #1e1e1e;
$color-grey-2: #424142;
$color-grey-3: #7f7f7f;
$color-grey-4: #b6b6b6;
$color-grey-5: #d7d7d7;
$color-grey-6: #f5f5f5;

$color-white: #fff;
$color-dark: #121212;
$color-background: $color-white;

$color-whatsapp-dark: #128c7e;
$color-whatsapp-light: #25d366;

$color-text-body: $color-grey-1;
$color-text-muted: $color-grey-4;

$font-size-smaller: 0.75rem;
$font-size-small: 0.9rem;
$font-size: 1rem;
$font-size-big: 1.125rem;
$font-size-bigger: 1.25rem;

$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

$link: $color-accent;

$box-shadow-small: 0px 0px 15px rgba(0, 0, 0, 0.1);
$box-shadow: 0px 8px 16px rgba(30, 0, 40, 0.08) !important;
$box-shadow-large: 0px 16px 48px rgba(30, 0, 40, 0.1);

$border-radius-min: 0.3rem;
$border-radius: 0.625rem;
$border-radius-cta: 2.5rem;

$trans: 0.2s all ease-in-out;

$header-1: 3rem;
$header-1-res: 2.18rem;
$header-2: 2.18rem;
$header-2-res: 1.875rem;
$header-3: 1.5rem;
$header-3-res: 1.25rem;
$header-4: 1.25rem;
$header-5: 1rem;
$header-6: 0.87rem;

$display-1: 6rem;
$display-2: 4.35rem;
$display-3: 3.45rem;
$display-4: 2.18rem;

$space-0: 0px;
$space-1: 1.5rem;
$space-2: 2.5rem;
$space-3: 4rem;
$space-4: 5rem;
$space-5: 7.5rem;

$typo-primary: "Poppins", sans-serif;
$typo-secondary: "Poppins", sans-serif;
