.ubicacion {
  a {
    color: $color-primary;
  }
  &__item {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
    margin-bottom: $space-3;
    @media screen and (max-width: 1400px) {
      gap: 2rem;
    }
  }

  &__map {
    @media screen and (min-width: 1200px) {
      flex-basis: 70%;
    }
    @media screen and (max-width: 1199px) {
      flex-basis: 60%;
    }
    @media screen and (max-width: 991px) {
      flex-basis: 56%;
    }
    @media screen and (max-width: 767px) {
      flex-basis: 100%;
    }
    iframe {
      width: 100%;
      filter: grayscale(1);
      border-radius: $border-radius;
      @media screen and (max-width: 767px) {
        height: 300px;
      }
    }
  }

  &__content {
  }

  &__direccion {
  }

  &__meta {
  }
}
