.page-template-page-ubicacion {
  .g-proyectos {
    display: none;
  }
}

.card-mapa {
  &__img {
    margin-bottom: 0.8rem;
    @media screen and (max-width: 575px) {
      max-width: 96% !important;
    }
  }

  &__title {
    color: $color-dark;
  }

  &__year {
    color: $color-grey-2;
  }
}
