html {
  scroll-behavior: smooth;
}

body {
  background-color: $color-background;
  font-size: $font-size;
  color: $color-dark;
  font-weight: $font-weight-normal;
  font-family: $typo-primary;

  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

a {
  color: $color-dark;
  transition: $trans;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    transition: $trans;
  }
}

.body-scroll {
  overflow-y: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.archive::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.archive {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.img-grey {
  filter: grayscale(1);
}
